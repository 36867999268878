<template>
  <div
    class="border border--radius-sm rounded q-ma-xs"
    :class="isOpen ? 'border--positive' : ''"
    @click="handleClick"
  >
    <div
      :class="isOpen ? 'border-bottom border-bottom--bold-1' : ''"
      class="q-pa-sm"
    >
      <div class="row items-center q-py-xs q-mb-xs text-caption">
        <q-badge
          :color="color"
          :label="$t(adapter)"
          class="q-py-xs q-mr-sm text-capitalize"
        />

        <div
          v-if="data.settings && data.settings['picking-endpoint-place-type']"
          class="text-capitalize q-mr-sm"
        >
          {{ data.settings['picking-endpoint-place-type'] }}
        </div>

        <div
          v-if="data.settings && data.settings['wave-order-limit'] && data.settings['wave-order-limit'] > 1"
          class="text-capitalize"
        >
          {{ data.settings['wave-order-limit'] }}
        </div>

        <q-space />

        <div v-if="tasks">
          {{ $t('Tasks') + ': ' + tasks }}
        </div>
      </div>

      <div
        class="text-h6 text-center text-capitalize text-weight-bold"
        style="white-space: normal !important;"
      >
        {{ data.name }}
      </div>

      <div class="text-caption text-weight-bold text-center">
        {{ $t('ID') + ': ' + data.id }}
      </div>
    </div>

    <q-slide-transition>
      <div
        v-if="sequences && sequences.length > 0 && isOpen"
      >
        <div
          v-for="(sequence, i) in sequences"
          :key="sequence.id"
          :class="i < sequences.length - 1 ? 'border-bottom q-pa-sm' : 'q-pa-sm'"
          @click="handleSequence(sequence)"
        >
          <div class="text-right">
            <q-badge
              :label="$t(sequence.state)"
              :color="sequenceStateColors[sequence.state]"
              class="q-py-xs text-capitalize"
            />
          </div>

          <div class="row">
            <div class="col">
              {{ $t('Tasks') }}
            </div>

            <div class="col row justify-center">
              <q-token
                v-for="(task, i) in sequence.taskStates"
                :key="i"
                :color="tasksStateColors[task.state]"
                class="q-mr-sm"
              >
                {{ `${task.count}${task.state[0].toUpperCase()}` }}
              </q-token>
            </div>

            <div class="col" />
          </div>

          <div class="text-subtitle1 text-weight-bold text-center">
            {{ sequence.id + '/' + sequence.extId }}

            <div v-if="sequence.stats && sequence.stats['orders-list'] && sequence.stats['orders-list'].length === 1">
              {{'Order :' + sequence.stats['orders-list'][0]}}
            </div>
          </div >

          <div v-if="(data?.settings['wave-order-limit'] === '1' || data?.settings['wave-order-limit'] === 1) && sequence?.tasks" class="text-subtitle1 text-weight-bold text-center">
              {{sequence.tasks[0]?.order + '/' + sequence.tasks[0]?.shopName + '(' + sequence.tasks[0]?.shop + ')'}}
          </div>
        </div>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'PickingQueue',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return {
          options: []
        }
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      sequenceStateColors: {
        new: 'positive',
        closed: 'negative',
        processing: 'info'
      },
      tasksStateColors: {
        new: 'teal',
        confirmed: 'warning',
        complete: 'positive',
        rejected: 'negative',
      },
      adapterColors: {
        routed_discrete: 'amber',
        routed_batch: 'green',
        totes: 'blue'
      },
      adapterLabels: {
        routed_discrete: 'By ID',
        routed_batch: 'Wave'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    color () {
      if (this.data.adapter === 'routed_batch' && this.data.settings && this.data.settings['wave-order-limit'] === 1) {
        return 'pink'
      }

      return this.adapterColors[this.data.adapter]
    },
    adapter () {
      if (this.data.adapter === 'routed_batch' && this.data.settings && this.data.settings['wave-order-limit'] === 1) {
        return 'Order by order'
      }

      return this.adapterLabels[this.data.adapter] || this.data.adapter
    },
    sequences () {
      return (this.data.sequences || []).filter(x => {
        // if (x && x.state === 'processing') {
        //   return x
        // }

        // return x && x.count && x.count > 0
        return x
      })
    },
    tasks () {
      if (this.data.tasks) {
        if (typeof this.data.tasks === 'object') {
          return ''
        }

        return this.data.tasks
      }

      return (this.data.options || [])
        .filter(val => val)
        .reduce((acc, val) => acc + Number(val.count), 0)
    }
  },
  methods: {
    handleSequence (sequence) {
      this.$emit('click', { ...this.rawData, sequence })
    },
    handleClick () {
      if (this.sequences && this.sequences.length > 0) {
        this.isOpen = !this.isOpen
        return
      }

      const data = {
        ...this.rawData,
        sequence: this.rawData.sequences
          ? this.rawData.sequences[0]
          : undefined
      }

      this.$emit('click', data)
    }
  }
}
</script>
